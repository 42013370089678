// import React from 'react'
import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';


const Header = () => {
  return (
    <div>
      <AppBar
        position="static"
        color="secondary"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <img src="./paulsoft64.png" alt="logo" />
          <Typography variant="h6" color="text.secondary" noWrap>
            {/* PaulSoft Games */}
          </Typography>
          <Box flexGrow={1} />
          <nav>
            <Link
              variant="button"
              color="text.secondary"
              href="./"
              sx={{ my: 1, mx: 1.5 }}
            >
              Home
            </Link>
            <Link
              variant="button"
              color="text.secondary"
              href="./Store"
              sx={{ my: 1, mx: 1.5 }}
            >
              Store
            </Link>
            <Link
              variant="button"
              color="text.secondary"
              href="/Support"
              sx={{ my: 1, mx: 1.5 }}
            >
              Support
            </Link>
            <Link
              variant="button"
              color="text.secondary"
              href="/PrivacyPolicy"
              sx={{ my: 1, mx: 1.5 }}
            >
              Privacy_Policy
            </Link>
            <Link
              variant="button"
              color="text.secondary"
              href="/TermsAndConditions"
              sx={{ my: 1, mx: 1.5 }}
            >
              Terms_&_Conditions
            </Link>
          </nav>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Header