// import React from 'react'
import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Copyright from './Copyright.js'


const Footer = () => {
  return (
    <div>
    <Box sx={{ bgcolor: '#222034', p: 6 }} component="footer">
      <Typography variant="h6" align="center" color="text.secondary" gutterBottom>
        PaulSoft LLC
      </Typography>
      <Copyright />
    </Box>
    </div>
  )
}

export default Footer