// import React from 'react'
import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import Footer from './components/Footer.js'
import Header from './components/Header.js'


const Store = () => {
  return (
    <div>
      <Header />
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          bgcolor: '#9faee9'
        }}
      >
        <Box
          component="img"
          alt="PaulSoft Games Banner Image"
          src="./banner3.png"
          width="100%"
        />
      </Grid>
      <Box
        sx={{
          bgcolor: '#222034',
          py: 2,
        }}
      >
        <Container maxWidth="sm">
          {/* <Typography
            component="h1"
            variant="h6"
            align="center"
            color="text.secondary"
            gutterBottom
          >
            <h1>Store</h1>
          </Typography> */}
          <Typography variant="h6" align="center" color="text.secondary" paragraph>
            <h1>Store</h1>
            <h3>Coming Soon!</h3>
          </Typography>
        </Container>
      </Box>
      <Footer />
    </div>
  )
}

export default Store