// import React from 'react'
import * as React from 'react';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';


const Copyright = () => {
  return (
    <div>
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://mui.com/">
          paulsoftgames.com
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </div>
  )
}

export default Copyright