// import React from 'react'
import * as React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';

import Footer from './components/Footer.js'
import Header from './components/Header.js'

const cards = [
  {
    id: 1,
    name: "Rucksack Rumble",
    header: "Inventory Autobattler!",
    details: "",
    img: "./rucksackrumble_flavor_00.png",
    alt: "Rucksack Rumble Logo",
    siteLink: "/playrucksackrumble",
    appStoreLink: "https://apps.apple.com/us/app/rucksack-rumble/id6475170941",
    playStoreLink: "https://play.google.com/store/apps/details?id=com.paulsoftllc.rucksackrumble",
    socialLinks: "",
    instagramLink: "https://www.instagram.com/paulsoftgames",
    redditLink: "https://www.reddit.com/r/paulsoftgames"
  },
  {
    id: 2,
    name: "Frog Game",
    header: "It's Frog Game!",
    details: "",
    img: "./froggame.png",
    alt: "Frog Game Logo",
    siteLink: "/playfroggame",
    appStoreLink: "https://apps.apple.com/us/app/frog-game/id6443645785?itsct=apps_box_badge&itscg=30200",
    playStoreLink: "https://play.google.com/store/apps/details?id=com.paulsoftllc.froggame",
    socialLinks: "",
    instagramLink: "https://www.instagram.com/paulsoftgames",
    redditLink: "https://www.reddit.com/r/paulsoftgames"
  },
  {
    id: 3,
    name: "Hog Game",
    header: "Grab That Hog!",
    details: "",
    img: "./hoggame.png",
    alt: "Hog Game Logo",
    siteLink: "/playhoggame",
    appStoreLink: "https://apps.apple.com/us/app/hog-game/id1671444149",
    playStoreLink: "https://play.google.com/store/apps/details?id=com.paulsoftllc.hoggame",
    socialLinks: "",
    instagramLink: "https://www.instagram.com/paulsoftgames",
    redditLink: "https://www.reddit.com/r/paulsoftgames"
  }
];


const PlayRucksackRumble = () => {
  const navigate = useNavigate();

  useEffect(() => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      window.location.assign('/PlayRucksackRumble.html');
    }
  
    if (/android/i.test(userAgent)) {
      window.location.assign('https://play.google.com/store/apps/details?id=com.paulsoftllc.rucksackrumble');
    }
  
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.assign('https://apps.apple.com/us/app/rucksack-rumble/id6475170941');
    }
  }, [])

  return (
    <div>
      <Header />
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          bgcolor: '#9faee9'
        }}
      >
        <Box
          component="img"
          alt="PaulSoft Games Banner Image"
          src="./banner4.png"
          width="100%"
        />
      </Grid>
      <Box
        sx={{
          bgcolor: '#222034',
          py: 2,
        }}
      >
        <Container maxWidth="sm">
          <Typography
            component="h2"
            variant="h3"
            align="center"
            color="text.secondary"
            gutterBottom
          >
            Rucksack Rumble
          </Typography>
          <Typography variant="subtitle1" align="center" color="text.secondary" paragraph>
            Dive into the Rucksack Rumble, the only mobile autobattler that combines strategic inventory management with exhilarating multiplayer battles. Assemble an almost infinite combination of rucksacks, weapons, trinket, bags, and backpack for battles against opponents all over the world. Use strategic thinking to outsmart your opponents and climb the ranking to Grand Champion, earning gold along the way to unlock new heroes. Join the rumble today!
          </Typography>
        </Container>
      </Box>
      <Container
        maxWidth="md"
        sx={{
          bgcolor: '#222034',
          py: 1,
        }}
      >
        <Grid container spacing={2}>
          {cards.map((card) => (
            <Grid item key={card} xs={12} sm={6} md={4} >
              <Card
                sx={{ 
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: "#ff6900"
                }}
              >
                <a href={card.siteLink}>
                  <CardMedia
                    component="img"
                    sx={{
                      // 16:9
                      pt: '0%',
                    }}
                    image={card.img}
                    alt="random"
                  />
                </a>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h4" component="h2" align="center" color="text.primary">
                    {card.header}
                  </Typography>
                  <Typography align="center" color="text.primary" >
                    {card.details}
                  </Typography>
                    {card.appStoreLink ? (
                      <a href={card.appStoreLink}>
                        <img
                          src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&releaseDate=1664928000&h=08bfb80478fe8bd7f52f5f10bd12d0ef"
                          alt={`Download ${card.name} from the Apple Store`}
                          width="100%"
                          height="auto"
                        />
                      </a>
                    ) : ( <p /> )}
                    {card.playStoreLink ? (
                      <a href={card.playStoreLink}>
                        <img
                          src="./playstore.png"
                          alt={`Download ${card.name} from the Google Play Store`}
                          width="100%"
                          height="auto"
                        />
                      </a>
                    ) : ( <p /> )}
                    {card.socialLinks ? (
                      <img
                        src="./clear.png"
                        alt={``}
                        width="11%"
                        height="auto"
                      />
                    ) : ( <p /> )}
                    {card.socialLinks ? (
                      <a href={card.instagramLink}>
                        <img
                          src="./Instagram_Glyph_White.png"
                          alt={`Visit the PaulSoft Games Instagram page`}
                          width="33%"
                          height="auto"
                        />
                      </a>
                    ) : ( <p /> )}
                    {card.socialLinks ? (
                      <img
                        src="./clear.png"
                        alt={``}
                        width="11%"
                        height="auto"
                      />
                    ) : ( <p /> )}
                    {card.socialLinks ? (
                      <a href={card.redditLink}>
                        <img
                          src="./Reddit_Mark_OnDark.png"
                          alt={`Visit the PaulSoft Games Reddit page`}
                          width="33%"
                          height="auto"
                        />
                      </a>
                    ) : ( <p /> )}
                    {card.socialLinks ? (
                      <img
                        src="./clear.png"
                        alt={``}
                        width="11%"
                        height="auto"
                      />
                    ) : ( <p /> )}
                </CardContent>
                {/* <CardActions>
                  <Button size="small">View</Button>
                  <Button size="small">Edit</Button>
                </CardActions> */}
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Footer />
    </div>
  )
}

export default PlayRucksackRumble